<template>
  <div class="container">
    <div v-if="idCard!=null">
      <div class="intro">
        本次操作为<span class="mcolor">法人</span>人脸认证，用于天美支付申请开通，请 <span class="mcolor">{{shortName}}</span> 的 <span class="mcolor">法人({{legalName}})</span>进行人脸识别认证，非法人请勿操作！   
        <!-- 本次操作剩余时间23小时23分 -->
        <!-- {{formName}}向您发起了<span class="mcolor">法人</span>认证邀请，用于天美 支付申请开通，请您进行<span class="mcolor">法人</span>人脸识别 认证 -->
      </div>
      <!-- <div class="intro" v-else>
        人脸识别功能验证你的身份信息，请确保为 <span class="mcolor">{{legalName}}</span> 本人操作
      </div> -->
      <div class="opt">
        <!-- <van-checkbox icon-size="14px" v-model="agree" shape="square" class="xieyi-box">
          <div class="xieyi-text column">
            您知悉并同意服务提供者授权天美支付按照《个人信息处理规则》实施个人信息处理并用于身份核验，详见
            <span class="mcolor">《个人信息处理规则》</span>
          </div>
        </van-checkbox> -->
        <button class="btn next-btn center float-ani" @click="startFace">开始人脸认证</button>
      </div>
    </div>
    <div v-else>
      <van-empty 
        description="查无数据" 
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters,useStore } from 'vuex'
  import { reactive, onMounted, toRefs, nextTick } from 'vue'
  import { useRouter } from 'vue-router'
  import { getNMBaseInfoApi } from "@/api/login";
  import { faceAuth, getFaceAuth } from "@/api/face";
  import { Toast,Notify } from 'vant'
  import store from '@/store'
  import wx from "weixin-js-sdk"
  import { urlencode, getUrlParam, getRouteQuery } from "@/utils/index";
  var toast1;
  export default {
    name: 'face',
    setup() {
      const router = useRouter();
      const state = reactive({
        appId: 'wxad104e066a4e583a',  //公众号appId
        // BizToken:null,  //核身流程标识
        merId: null,    //商户id
        channelCode: 'LKL',  //渠道
        type: 0,      //0当前法人 1分享给法人

        idCard: null,   //法人身份证
        legalName: null,   //法人姓名  
        shortName: null, //公司名称
        time:null,  //分享时间戳
        agree: false, //是否同意协议

        code: '',
      })
      onMounted(async () => {
        state.merId = router.currentRoute._rawValue.query.merid||null;
        state.channelCode = router.currentRoute._rawValue.query.channelcode||'LKL';
        state.type = router.currentRoute._rawValue.query.type||0;

        let BizToken = router.currentRoute._rawValue.query.BizToken||router.currentRoute._rawValue.query.bizToken||null;
        if(!BizToken){
          BizToken = new URL(location.href).searchParams.get('BizToken');
        }
        //如果连接地址带有核身标识字段，则是核身完成后回调,请求查询人脸核身结果
        if(BizToken){
          getFaceAuth(BizToken).then(res => {
            if(res.code == 200 && res.data && res.data.success==true){
              // Notify({ type: 'primary', message:  '恭喜您，人脸识别认证成功，请耐心等待审核！'});
              Toast('恭喜您，人脸识别认证成功，请耐心等待审核！');
              store.dispatch('QueryUserIsBind')   

              wx.ready(function () { //通过ready接口处理成功验证
                wx.miniProgram.getEnv(function (res) {
                  if (res.miniprogram) {
                    // 小程序环境下逻辑
                    console.log("小程序环境下");
                    wx.miniProgram.reLaunch({
                      url:'/pages/index/index'
                    });
                  } else {
                    //非小程序环境下逻辑
                    console.log("非小程序环境下");
                    router.push({name: "index"});
                  }
                });         
              });         
            }else{
              //人脸核身失败，跳转到失败页面
              // Notify({ type: 'primary', message:  res.data.merId+','+res.data.channelCode+','+res.data.type});
              router.push({
                name: "faceerr",
                query:{
                  merid: res.data.merId,
                  channelcode: res.data.channelCode,
                  type: res.data.type
                }
              });
            }
          });
        }else{
          state.time = router.currentRoute._rawValue.query.time||null;
          let param = {
            merId:state.merId,
            channelCode:state.channelCode,
          }
          getNMBaseInfoApi(param).then(res => {
            if(res && res.data){
              const data = res.data;
              state.shortName = data.shortName;
              state.idCard = data.cardNo;
              state.legalName = data.legalName;
            }else{
              Toast(res.msg||'查询异常');
            }
          })
          //是否授权
          let code = getRouteQuery().code;
          // 3.如果有code，则已经授权
          if (code) {
            toast1 = Toast.loading({
              message: '加载中...',
              forbidClick: true,
            });
            state.code = code;
            mpLogin();   //继续下一步获取openid
          }
        }
      })
      
      //开始人脸
      const startFace = () => {        
        var TOKEN = store.getters['token'];
        if(!TOKEN){
          toast1 = Toast.loading({
            message: '加载中...',
            forbidClick: true,
          });
          let url = window.location.href;          
          state.code = getUrlParam('code');
          if (state.code) {
            mpLogin();   //继续下一步获取openid
          } else {
            var url_code = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + state.appId + "&redirect_uri=" + urlencode(url) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
            window.location.replace(url_code);//打开这个链接，你的url后面就会跟上code的参数 
          }  
        }else{
          let param = {
            merId:state.merId,
            idCard:state.idCard,
            name:state.legalName,
            channelCode:state.channelCode,
            type: state.type
          }
          faceAuth(param).then(res => {
            if(toast1){
              toast1.clear();
            }
            if(res.code == 200){
              let data = res.data;
              window.location.href = data.url;
            }else{
              Toast(res.msg || '系统未知错误，请重试');
            }
          }).catch((err) => {
            console.log(err);  
            if(toast1){
              toast1.clear();
            }        
          });
        }   
      }         

      //登录，获取token
      const mpLogin = () => {
        const para = {
          'code': state.code
        }
        store.dispatch('LoginMp', para).then(res => {
          if (res.code === 200) {
            startFace();
            //查询是否注册
            // fnQueryUserIsBind();
            // console.log(res.msg);
          } else {
            Toast(res.msg || '系统未知错误，请重试');
            if(toast1){
              toast1.clear();
            }
          }
          // return false
        }).catch((err) => {
          console.log(err);  
          if(toast1){
            toast1.clear();
          }        
        })
      }
  
      return {
        ...toRefs(state),
        startFace
      }
    },
  }
</script>
<style lang="less" scoped>
  @import '../assets/style/mixin';
  .container{
    background-color: #ffffff;
    font-size: 12px;
  }
  .intro{
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    margin: 40px 30px 40px 22.5px;
  }
  .opt{
    position: fixed;
    left: 15px;
    right: 15px;
    bottom: 30px;
    
  }
  .xieyi-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: flex-start;
  }
  .xieyi-text{
    /* margin-left: 6.5px; */
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    margin-top: -1px;
  }
  .next-btn{
    width: 345px!important;
    padding: 0!important;
    height: 44px;
    background: #FA3474;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: env(safe-area-inset-bottom);
  }
  .xieyi-check{
    width: 13px;
    height: 13px;
  }
  checkbox .wx-checkbox-input{
    width: 13px; /* 背景的宽 */
    height: 13px; /* 背景的高 */
  }
</style>