
import request from "@/utils/request";

// 开始人脸核身
export function faceAuth(data) {
  return request({
    url: '/common/faceid/start',
    method: 'post',
    data
  })
}

// 查询人脸核身结果
export function getFaceAuth(bizToken) {
  return request({
    url: '/common/faceid/getResult?bizToken='+bizToken,
    method: 'post'
  })
}